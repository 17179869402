<template>
  <CRow class="deal-modal">
    <CCol :md="mode === 'Add' ? 12 : 7">
      <CRow>
        <CCol md="12" class="text-right" v-show="mode === 'Edit'">
          <CIcon
            size="xl"
            name="cil-check-circle"
            class="text-success status-icon"
            v-show="status === 'WON'"
          />
          <CIcon
            size="xl"
            name="cil-x-circle"
            class="text-danger status-icon"
            v-show="status === 'LOST'"
          />
          <CButton
            class="btn-success mb-3"
            v-show="status === 'PROCESSING'"
            @click="changeDealStatus('WON')"
          >
            Gewonnen
          </CButton>

          <CButton
            class="btn-primary ml-3 mb-3"
            v-show="status !== 'PROCESSING'"
            @click="changeDealStatus('PROCESSING')"
          >
            Erneut öffnen
          </CButton>

          <CButton
            class="btn-danger mb-3 ml-3"
            v-show="status === 'PROCESSING'"
            @click="changeDealStatus('LOST')"
          >
            Verloren
          </CButton>
        </CCol>

        <CCol md="12" style="position: relative">
          <model-select
            v-model="selectedPeople"
            :options="peoples"
            :placeholder="!(selectedPeople.value) && !(leadPeopleName) ? 'Kontaktperson' : ''"
            :class="(showPeopleError && submitted) ? 'mb-1' : 'mb-3'"
            class="model-select"
          />
          <span v-if="!(selectedPeople.value)" class="new-people">
            {{leadPeopleName}}
          </span>
          <CBadge v-if="!(selectedPeople.value) && leadPeopleName" class="new-people-badge" color="primary">Als neuen Kontakt anlegen</CBadge>
          <label class="text-danger ml-1" v-show="showPeopleError && submitted">Bitte wählen Sie eine Kontaktperson aus</label>
        </CCol>

        <CCol md="12" class="mb-3 d-flex justify-content-between" style="margin-top: -10px;" v-if="similarPeople && !(selectedPeople.value)">
          <div>
            <CIcon name="cil-warning"></CIcon>
            &nbsp;&nbsp;
            <span>Dieser Kontakt scheint bereits zu bestehen!</span>
          </div>

          <div @click="showSimilarPeople = true" class="cursor-pointer">
            <span>Prüfen</span>
          </div>
        </CCol>

        <CCol md="12" class="mb-3 cursor-pointer" v-if="showSimilarPeople && similarPeople">
          <div class="people-box" @click="setAsSimilarPeople">
            <span>
              {{`${similarPeople.text}(${leadPeopleEmail})`}}
            </span>
          </div>

          <div class="people-box" @click="addAsNewPeople">
            <span>
              Als neuen Kontakt anlegen
            </span>
          </div>
        </CCol>

        <CCol md="12">
          <model-select
            v-model="selectedGroup"
            :options="groups"
            placeholder="Organisation"
            class="model-select mb-3"
          />
        </CCol>

        <CCol md="12">
          <CInput
            v-model="name"
            placeholder="Titel"
            required
          />
        </CCol>

        <CCol md="6">
          <CInput
            type="number"
            placeholder="Wert"
            v-model="value"
            required
          />
        </CCol>

        <CCol md="6">
          <CSelect
            value="1"
            :options="[{value: 2, label: 'EUR'}]"
          />
        </CCol>

        <CCol md="12">
          <CSelect
            :value="selectedPipeline"
            :options="pipelines"
            @update:value="changePipeline($event)"
            required
          />
          <span
            v-if="!pipelines || (pipelines && pipelines.length === 0)"
            style="margin-top: -10px;display: block;"
          >Es wurde noch keine Pipeline angelegt - <router-link to="/deals/pipeline">Pipeline</router-link> erstellen</span>
        </CCol>

        <CCol md="12">
          <CRow style="height: 30px;" class="px-3 mb-3">
            <CCol
              :key="index"
              v-for="(step, index) of steps"
              class="pipeline-step"
              :class="currentStep.orderNumber >= step.orderNumber ? (status === 'LOST' ? 'passed-lost' : 'passed') : null"
              @click="currentStep = step"
              v-c-tooltip="step.name"
            >
            </CCol>
          </CRow>
        </CCol>

        <CCol md="12">
          <datepicker
            v-model="closeDate"
            :language="datepickerLangGerman"
            :monday-first="true"
            format="dd/MM/yyyy"
          />
        </CCol>
      </CRow>
    </CCol>

    <CCol md="5" v-if="mode === 'Edit'">
      <CRow>
        <CCol md="12" class="text-right">
          <CButton
            color="success"
            v-c-tooltip="'Erstellen Sie Notizen'"
            v-on:click="showCreateNotesModal"
          >
            Notiz hinzufügen
          </CButton>
          &nbsp;&nbsp;&nbsp;
          <CButton
            color="success"
            v-c-tooltip="'Eine Aktivität erstellen'"
            v-on:click="showCreateActivityModal()"
          >
            Aktivität hinzufügen
          </CButton>
        </CCol>
      </CRow>

      <CRow>
        <CCol md="12">
        <div class="deal-activity-filter">
          <span
            class="font-weight-bold cursor-pointer tab"
            :class="selectedCategory === 'all' && 'text-primary'"
            @click="selectedCategory = 'all'"
          >
            Alle
          </span>
          &nbsp;&nbsp;
          <span
            class="font-weight-bold cursor-pointer tab"
            :class="selectedCategory === 'notes' && 'text-primary'"
            @click="selectedCategory = 'notes'"
          >
            Notizen
          </span>
          &nbsp;&nbsp;
          <span
            class="font-weight-bold cursor-pointer tab"
            :class="selectedCategory === 'activities' && 'text-primary'"
            @click="selectedCategory = 'activities'"
          >
            Aktivitäten
          </span>
          </div>
        </CCol>

        <CCol
          md="12"
          v-for="(attach, index) of attaches"
          :key="`attach-${index}`"
        >
          <div
            v-if="attach.category === 'notes'"
            class="deal-activity-wrapper"
          >
            <div class="deal-activity">
              <div>
                <CButton
                  size="sm"
                  color="dark"
                  variant="outline"
                  class="rounded-circle"
                >
                  <CIcon name="cil-notes"/>
                </CButton>
              </div>

              <div class="deal-activity-information">
                <div class="deal-notes">
                  &nbsp;&nbsp;&nbsp;
                  <span
                    v-html="attach.notes"
                  ></span>
                </div>

                <div class="deal-activity-start-time">
                <span>
                  {{formatDate(attach.created)}}
                </span>
                </div>
              </div>
            </div>

            <div>
              <CDropdown
                toggler-text="..."
                class="m-2 activity-action"
                placement="bottom-start"
              >
                <CDropdownItem
                  v-on:click="showUpdateNotesModal(attach)"
                >
                  Bearbeiten
                </CDropdownItem>
                <CDropdownItem
                  v-on:click="deleteNotes(attach)"
                >
                  Löschen
                </CDropdownItem>
              </CDropdown>
            </div>
          </div>

          <div
            class="deal-activity-wrapper"
            v-else
          >
            <div class="deal-activity">
              <div>
                <CButton
                  size="sm"
                  color="dark"
                  variant="outline"
                  class="rounded-circle"
                  v-c-tooltip="getTooltip(attach.type)"
                >
                  <CIcon :name="getIcon(attach.type)"/>
                </CButton>
              </div>

              <div class="deal-activity-information">
                <div class="deal-activity-title">
                  <CIcon
                    class="text-success"
                    name="cil-check-circle"
                    v-c-tooltip="'Done'"
                    v-if="attach.done === true"
                  />
                  <span
                    v-on:click="showUpdateActivityModal(attach)"
                  >&nbsp;&nbsp;&nbsp;{{attach.title}}</span>
                </div>

                <div class="deal-activity-start-time">
                <span>
                  {{attach.startTime}}
                </span>
                </div>
              </div>
            </div>

            <div>
              <CDropdown
                toggler-text="..."
                class="m-2 activity-action"
                placement="bottom-start"
              >
                <CDropdownItem
                  v-on:click="showUpdateActivityModal(attach)"
                >
                  Bearbeiten
                </CDropdownItem>
                <CDropdownItem
                  v-on:click="deleteActivity(attach)"
                >
                  Löschen
                </CDropdownItem>
              </CDropdown>
            </div>
          </div>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
  import axios from "axios"
  import {ModelSelect} from 'vue-search-select'
  import Datepicker from "vuejs-datepicker";
  import { de } from 'vuejs-datepicker/dist/locale'
  import {activityTypes} from "../../../config/constant";
  import moment from "moment"

  export default {
    props: [
      'pipelines',
      'originalPipelines',
      'applyAdd',
      'applyUpdate',
      'mode',
      'showCreateActivityModal',
      'showUpdateActivityModal',
      'showCreateNotesModal',
      'showUpdateNotesModal',
      'deleteActivity',
      'deleteNotes',
    ],
    components: {
      ModelSelect,
      Datepicker,
    },
    mounted() {
      this.loadPeoples()
      this.loadGroups()
    },
    data: () => ({
      datepickerLangGerman: de,
      peoples: [],
      selectedPeople: {
        value: '',
        text: '',
        realmGroup: null,
      },
      groups: [],
      selectedGroup: {
        value: '',
        text: ''
      },
      name: '',
      value: '',
      selectedPipeline: '',
      currentStep: null,
      closeDate: new Date((new Date()).setDate((new Date()).getDate() + 10)),
      showPeopleError: false,
      submitted: false,
      selectedDealId: null,
      status: null,
      activities: [],
      notesList: [],
      // person information when converting lead to deal
      leadPeopleName: '',
      leadPeopleEmail: '',
      leadPeoplePhone: '',
      showSimilarPeople: false,
      stopWatchSelectedPeople: false,
      submitting: false,
      selectedCategory: 'all', // all or notes or activities
    }),
    methods: {
      formatDate(value) {
        moment.locale('de')
        return moment(value).format('LLL')
      },
      // add people
      async addPeople(data) {
        try {
          this.submitting = true
          this.stopWatchSelectedPeople = true
          const response = await axios.post('/realm-people', data)
          this.peoples.push({
            value: response.data.id,
            text: response.data.name,
            realmGroup: response.data.realmGroup,
            realmPeopleEmails: response.data.realmPeopleEmails,
            realmPeoplePhones: response.data.realmPeoplePhones,
          })

          this.selectedPeople = {
            value: response.data.id,
            text: response.data.name,
            realmGroup: response.data.realmGroup,
          }

          this.initializeLeadPeople()
        } catch(e) {
          console.log(e)
        } finally {
          this.submitting = false
        }
      },
      // load contact persons
      loadPeoples() {
        axios.get('/realm-people')
          .then((response) => {
            this.peoples = []
            response.data && response.data.forEach(entity => {
              this.peoples.push({
                value: entity.id,
                text: entity.name,
                realmGroup: entity.realmGroup,
                realmPeopleEmails: entity.realmPeopleEmails,
                realmPeoplePhones: entity.realmPeoplePhones,
              })
            })
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
          })
      },
      // load groups
      loadGroups() {
        axios.get('/realm-group')
          .then((response) => {
            this.groups = []
            response.data && response.data.map(entity => {
              this.groups.push({
                value: entity.id,
                text: entity.title
              })
            })
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.tableShowLoading = false
          })
      },
      async submit() {
        this.submitted = true

        // if add as new contact people
        if (!this.selectedPeople.value && this.leadPeopleName) {
          await this.addAsNewPeople()
        }

        if (!(this.selectedPeople.value && this.selectedPeople.value !== '')) {
          this.showPeopleError = true
        } else {
          const data = {
            name: this.name,
            realmPeople: {
              id: this.selectedPeople.value
            },
            value: this.value,
            pipeline: {
              id: this.selectedPipeline
            },
            pipelineStep: {
              id: this.currentStep.id
            },
            closeDate: this.closeDate
          }

          if (this.selectedGroup && this.selectedGroup.value) {
            data['realmGroup'] = {
              id: this.selectedGroup.value
            }
          }

          if (this.mode === 'Add') {
            const ret = await axios.post(`/deal`, data)
            this.applyAdd(ret.data)
          } else {
            const ret = await axios.put(`/deal/${this.selectedDealId}`, data)
            this.applyUpdate(ret.data)
          }
        }
      },
      changePipeline(evt) {
        this.selectedPipeline = evt
        const entity = this.originalPipelines &&
          this.originalPipelines.find(pipeline => parseInt(pipeline.id) === parseInt(this.selectedPipeline))
        this.currentStep = entity && entity.pipelineSteps && entity.pipelineSteps[0]
      },
      // change deal status(processing, won, lost)
      async changeDealStatus(status) {
        if (this.selectedDealId) {
          await axios.post(`/deal/change-status/${this.selectedDealId}/${status}`)
          this.status = status
        }
      },
      getIcon(type) {
        const item = activityTypes && activityTypes.find(entity => entity.value === type)
        return item ? item.icon : ''
      },
      getTooltip(type) {
        const item = activityTypes && activityTypes.find(entity => entity.value === type)
        return item ? item.text : ''
      },
      setAsSimilarPeople() {
        this.selectedPeople = {
          value: this.similarPeople.value,
          text: this.similarPeople.text,
          realmGroup: this.similarPeople.realmGroup
        }
        this.showSimilarPeople = false
      },
      async addAsNewPeople() {
        const data = {
          name: this.leadPeopleName,
          realmGroup: {
            id: null,
          },
          realmPeoplePhones: [{
            phone: this.leadPeoplePhone,
          }],
          realmPeopleEmails: [{
            email: this.leadPeopleEmail,
          }],
        }

        await this.addPeople(data);
      },
      initializeLeadPeople() {
        this.leadPeopleName = ''
        this.leadPeopleEmail = ''
        this.leadPeoplePhone = ''
      }
    },
    watch: {
      selectedPeople: {
        deep: true,
        handler(newVal) {
          if (this.submitted) {
            this.showPeopleError = (!newVal.value || newVal.value === '')
          }

          if (this.stopWatchSelectedPeople) {
            this.stopWatchSelectedPeople = false
          } else if (newVal && newVal.text) {
            this.name = newVal && newVal.text + ' - Deal'
          }

          const group = newVal && newVal.realmGroup && newVal.realmGroup

          this.selectedGroup = {
            value: group && group.id,
            text: group && group.name,
          }
        }
      },
    },
    computed: {
      steps: function () {
        const pipeline = this.originalPipelines && this.originalPipelines.find(entity => entity.id === this.selectedPipeline)
        return pipeline && pipeline.pipelineSteps && pipeline.pipelineSteps.sort((a, b) => a.orderNumber - b.orderNumber)
      },
      similarPeople: function () {
        if (this.peoples && this.peoples.length > 0) {
          for (let i = 0; i < this.peoples.length; i++) {
            const item = this.peoples[i]
            const nameCheck = (item.text === this.leadPeopleName)
            if (!nameCheck) {
              continue
            }
            const emailCheck = item.realmPeopleEmails &&
              item.realmPeopleEmails.findIndex(entity => (entity.email === this.leadPeopleEmail))
            if (emailCheck === -1) {
              continue
            }
            const phoneCheck = item.realmPeoplePhones &&
              item.realmPeoplePhones.findIndex(entity => (entity.phone === this.leadPeoplePhone))
            if (phoneCheck !== -1) {
              return item
            }
          }
        }
        return null
      },
      attaches: function () {
        if (this.selectedCategory === 'all') {
          const all = this.notesList.concat(this.activities)
          console.log(all)
          return all.sort((a, b) => {
            return (a.date - b.date)
          })
        } else if (this.selectedCategory === 'notes') {
          return this.notesList
        } else {
          return this.activities
        }
      }
    }
  }
</script>

<style>
  .pipeline-step {
    cursor: pointer;
    border: 0.1rem solid #d8dbe0;
  }

  .passed {
    background-color: #05B9AE;
  }

  .passed-lost {
    background-color: #f94839;
  }

  .vdp-datepicker input {
    height: 33px;
    width: 100%;
    border: 1px #d8dbe0 solid;
    padding-left: 13px;
  }

  .vdp-datepicker input:focus {
    border: 1px #ccc7f6 solid;
  }

  .model-select {
    color: #768192 !important;
  }

  .search.selection.dropdown.disabled {
    opacity: 1;
  }

  .status-icon {
    margin-top: -15px;
  }

  .deal-activity-wrapper {
    display: flex;
    justify-content: space-between;
    border: solid 1px #e5e5e5;
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    width: 95%;
  }

  .deal-activity {
    display: flex;
    align-items: center;
  }

  .deal-activity-information {
    margin-left: 30px;
  }

  .deal-activity-title {
    font-size: 16px;
    font-weight: bold;
  }

  .deal-activity-title > span {
    cursor: pointer;
  }

  .deal-activity-title > span:hover {
    color: blue;
  }

  .deal-activity-start-time {
    margin-top: 10px;
  }
  .deal-notes img {
    max-width: 300px;
}
.deal-activity-filter {
    margin: 30px 0px;
}

.deal-activity-filter .text-primary {
    color: #26292c!important;
    border-bottom: 2px solid #721ea9;
}
.deal-activity-filter .tab {
    font: 400 13px/16px Source Sans Pro,sans-serif;
    padding: 5px 12px;
}

  .activity-action {
    background-color: transparent;
    color: gray;
  }

  .activity-action .dropdown-menu {
    margin-top: 10px !important;
  }

  .deal-modal {
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .new-people {
    position: absolute;
    top: 10px;
    left: 30px;
  }

  .new-people-badge {
    position: absolute;
    top: 7px;
    right: 50px;
    padding: 8px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .people-box {
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-shadow: 1px 1px 5px gray;
    height: 40px;
  }
  .people-box:hover {
    background: gray;
    color: white;
  }

  .tab {
    font-size: 18px;
  }
</style>
